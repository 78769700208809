<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-11 col-md-6" style="margin-top: 40px">
        <p class="legal-header">{{ t('imprint_header') }}</p>

        <h5 class="legal-text" style="font-size: 1.2em">Medienteilhaber:</h5>

        <hr>

        <p class="legal-text">
          Pixeltrend GmbH &‌ Co KG<br/>
          Industriezeile 35<br/>
          4020 Linz
        </p>
        <p class="legal-text">
          <b>Registereintrag:</b><br/>
          Rechtsform: Kommanditgesellschaft (GmbH & Co KG)<br/>
          Firmenbuchnummer: FN 478882 p<br/>
          Firmenbuchgericht: Landesgericht Linz
        </p>

        <hr>

        <p class="legal-text">
          candybytes GmbH<br/>
          Kornstraße 7A<br/>
          4060 Leonding
        </p>
        <p class="legal-text">
          <b>Registereintrag:</b><br/>
          Rechtsform: Gesellschaft mit beschränkter Haftung (GmbH)<br/>
          Firmenbuchnummer: FN 454690 m<br/>
          Firmenbuchgericht: Landesgericht Linz
        </p>

        <hr>

        <a class="btn" id="mailButton" href="mailto:privacy@pixeltrend.at">{{ t('contact') }}: privacy@pixeltrend.at</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { t }
  }
})
</script>

<style scoped lang="sass">
.legal-header
  font-size: 1.5em
  font-weight: 600
  text-align: center

.legal-text
  text-align: center
  white-space: pre-line

#mailButton
  background: $color_surface
  color: $color_on_background
  display: table
  margin-left: auto
  margin-right: auto
  &:hover
    background: $color_primary_overlay_light
    color: $color_on_background

</style>

<i18n>
{
  "de": {
    "imprint_header": "Impressum",
    // "imprint_text": "Medienteilhaber:\n\nPixeltrend GmbH & Co KG\nIndustriezeile 35\n4020 Linz\n\nRegistereintrag:\nRechtsform: Kommanditgesellschaft (GmbH & Co KG)\nFirmenbuchnummer: FN 478882 p\nFirmenbuchgericht: Landesgericht Linz\n\n\ncandybytes GmbH\nKornstraße 7A\n4060 Leonding\n\nRegistereintrag:\nRechtsform: Gesellschaft mit beschränkter Haftung (GmbH)\nFirmenbuchnummer: FN 454690 m\nFirmenbuchgericht: Landesgericht Linz\n",
    "contact": "Kontakt"
  },
  "en": {
    "imprint_header": "Imprint",
    // "imprint_text": "Medienteilhaber:\n\nPixeltrend GmbH & Co KG\nIndustriezeile 35\n4020 Linz\n\nRegistereintrag:\nRechtsform: Kommanditgesellschaft (GmbH & Co KG)\nFirmenbuchnummer: FN 478882 p\nFirmenbuchgericht: Landesgericht Linz\n\n\ncandybytes GmbH\nKornstraße 7A\n4060 Leonding\n\nRegistereintrag:\nRechtsform: Gesellschaft mit beschränkter Haftung (GmbH)\nFirmenbuchnummer: FN 454690 m\nFirmenbuchgericht: Landesgericht Linz\n",
    "contact": "Contact"
  }
}
</i18n>
